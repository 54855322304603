<template>
  <div>
    <attached-file-modal
      ref="invoiceModal"
      resource-name="purchase_orders"
      attached-file-name="invoice"
    />
    <purchase-order-filter-dialog
      ref="purchaseOrderFilterDialog"
    />
    <div class="overflow-hidden">
      <div id="actionBar">
        <div class="flex items-center justify-between p-3">
          <h1 class="heading">
            Purchase Orders
          </h1>
          <div>
            <button
              class="btn btn-primary-invert px-2"
              @click="openFilterDialog()"
            >
              Update Filter
            </button>
            <button
              class="btn btn-primary-invert px-2"
              @click="loadData()"
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <ag-grid-vue
        id="purchaseOrderGrid"
        class="ag-theme-alpine"
        row-selection="single"
        row-drag-managed="true"
        animate-rows="true"
        :column-defs="columnDefs"
        :row-data="purchaseOrders"
        :get-row-node-id="getRowNodeId"
        :tooltip-show-delay="0"
        :default-col-def="{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          floatingFilterComponentParams: {suppressFilterButton:true},
          width: 100,
        }"
        :modules="modules"
        @grid-ready="onGridReady"
        @sort-changed="onSortChanged"
        @filter-changed="onFilterChanged"
      />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { formatDate, DateComparator, formatDateTime } from '../lib/date_helper.js';
  import AttachedFileModal from '../components/shared/AttachedFileModal.vue'
  import Button from '../components/cellRendererFramework/Button.vue'
  import PurchaseOrderFilterDialog from '../components/PurchaseOrderFilterDialog.vue'

  export default {
    components: {
      AttachedFileModal,
      AgGridVue,
      Button,
      PurchaseOrderFilterDialog
    },

    data () {
      return {
        purchaseOrders: [],
        gridApi: null,
        getRowNodeId: null,
        modules: [ClientSideRowModelModule],
        invoicePoId: null
      }
    },

    computed: {
      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          {
            headerName: 'Ref#',
            field: 'purchase_order_number',
            width: 100,
          },
          {
            headerName: 'Business Unit',
            field: 'business_unit_name',
            tooltipField: 'business_unit_name',
            width: 200,
          },
          {
            headerName: 'Delivery Date',
            field: 'delivery_date',
            valueFormatter: (params) => { return formatDate(params.data.delivery_date) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: (params) => { return new Date(params.value + ' 00:00') - new Date() < 86400000 ? ['text-center', 'text-red-600', 'font-bold'] : 'text-center' },
            sort: 'desc'
          },
          {
            headerName: 'Order Comments',
            field: 'order_comments',
            tooltipField: 'order_comments',
            width: 200,
          },
          {
            headerName: 'Order Description',
            field: 'order_descriptions',
            tooltipField: 'order_descriptions',
            width: 200,
          },
           {
            headerName: 'Stores',
            field: 'store_names',
            tooltipField: 'store_names',
            width: 200,
          },
          {
            headerName: 'Delivery Points',
            field: 'delivery_point_names',
            tooltipField: 'delivery_point_names',
            width: 200,
          },
          {
            headerName: 'Buyer Name',
            field: 'buyer_name',
            tooltipField: 'buyer_name',
            width: 200,
          },
          {
            headerName: 'Created at',
            field: 'created_at',
            valueFormatter: (params) => { return formatDateTime(params.data.created_at) },
            width: 300,
          },
          {
            cellRendererFramework: 'Button',
            cellRendererParams: { clickFunction: this.downloadPoFile, buttonName: 'Download', class: 'btn-small btn-primary-invert' },
            pinned: 'right',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 130,
            cellStyle: { textAlign: 'center', background: '#FCFCFC' },
          },
          {
            cellRendererFramework: 'Button',
            cellRendererParams: { clickFunction: this.openInvoicesDialog, buttonName: 'Upload Invoices', class: 'btn-small btn-primary-invert' },
            pinned: 'right',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 160,
            cellStyle: { textAlign: 'center', background: '#FCFCFC' },
          }
        ]
      },
    },

    created () {
      window.addEventListener("resize", this.gridAutofit)
    },

    beforeMount () {
      this.getRowNodeId = data => { return data.id } //To set own Row ID instead of ID generated by AgGrid (https://www.ag-grid.com/javascript-grid-callbacks/)
    },

    destroyed () {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      openFilterDialog () {
        this.$refs.purchaseOrderFilterDialog.open();
      },
      openInvoicesDialog (id) {
        this.$refs.invoiceModal.open(id)
      },

      downloadPoFile (id) {
        this.$http.get(`/purchase_orders/${id}/download_po_file`).then(response => {
          if (response.data.error) {
            this.$noty.error(response.data.message)
          } else {
            window.open(response.data.url, '_blank');
          }
        })
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      loadData () {
        this.gridApi.showLoadingOverlay()
        let params = {
          delivery_date_from: this.$route.query.deliveryDateFrom,
          delivery_date_to: this.$route.query.deliveryDateTo,
        }
        this.$http.get('/purchase_orders', {params: params}).then(response => {
          this.purchaseOrders = response.data
        })
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      gridAutofit () {
        if (!document.querySelector('#purchaseOrderGrid')) return

        document.querySelector('#purchaseOrderGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      }
    }
  }
</script>
