<template>
  <transition name="fade">
    <div
      v-if="showModal"
      class="h-screen w-full flex flex-col items-center justify-center bg-teal-lightest font-sans absolute z-10 modal top-0 left-0"
      @close="close"
    >
      <div class="h-screen w-full bg-black opacity-75" />
      <div class="h-screen w-full absolute flex items-center justify-center">
        <div
          id="modalDialog"
          class="bg-white rounded-lg shadow"
          :style="modalStyle"
        >
          <h1
            id="modalDialogHeader"
            class="modal-heading"
          >
            <slot name="header" />
          </h1>

          <div>
            <slot />
          </div>

          <div
            id="modalDialogFooter"
            class="p-4"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: {
      modalStyle: {
        type: String,
        default: 'width: 450px'
      }
    },

    data () {
      return {
        showModal: false
      }
    },

    methods: {
      open () {
        this.showModal = true
      },

      close () {
        this.showModal = false
      }
    }
  }
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
