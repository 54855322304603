<template>
  <div>
    <log-modal ref="logModal" />
    <confirmation-modal
      ref="resetConfirmationModal"
      confirm-button-text="Reset"
      :confirm="resetPassword"
      :message="resetWarningMessage"
    />
    <confirmation-modal
      ref="deleteConfirmationModal"
      confirm-button-text="Delete"
      :confirm="deleteRows"
      :message="deleteWarningMessage"
    />
    <user-creation-modal
      ref="userCreationModal"
      :vendors="vendors"
    />
    <div
      id="actionBar"
      class="flex justify-end"
    >
      <div class="flex items-center justify-between p-3">
        <div>
          <button
            class="btn btn-primary-invert"
            @click="save()"
          >
            Save
          </button>
          <button
            class="btn btn-primary-invert"
            @click="openCreationModal()"
          >
            Create
          </button>
          <button
            class="btn btn-primary-invert"
            @click="deleteConfirmation()"
          >
            Delete
          </button>
          <button
            class="btn btn-primary-invert"
            @click="viewLog()"
          >
            View Log
          </button>
          <button
            class="btn btn-primary-invert"
            @click="resetConfirmation()"
          >
            Reset Password
          </button>
          <button
            class="btn btn-primary-invert px-2"
            @click="loadData()"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>

    <ag-grid-vue
      id="userGrid"
      class="ag-theme-alpine"
      row-drag-managed="true"
      animate-rows="true"
      row-selection="multiple"
      enter-moves-down="true"
      enter-moves-down-after-edit="true"
      stop-editing-when-grid-loses-focus="true"
      :column-defs="columnDefs"
      :row-data="users"
      :default-col-def="{
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {suppressFilterButton:true},
        width: 100,
      }"
      :modules="modules"
      @grid-ready="onGridReady"
      @cell-value-changed="onCellValueChanged"
      @sort-changed="onSortChanged"
      @filter-changed="onFilterChanged"
    />
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import bus               from '../../event-bus.js'
  import ConfirmationModal from '../../components/shared/ConfirmationModal.vue'
  import LogModal          from '../shared/LogModal.vue'
  import UserCreationModal from './UserCreationModal.vue'

  export default {
    components: {
      AgGridVue,
      ConfirmationModal,
      LogModal,
      UserCreationModal
    },

    data () {
      return {
        gridApi: null,
        users: [],
        vendors: [],
        selectedRowCount: 0,
        modules: [ClientSideRowModelModule]
      }
    },

    computed: {
      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            pinned: 'left',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 50,
          },
          {
            headerName: 'Email',
            field: 'email',
            editable: true,
            width: 200,
          },
          {
            headerName: 'Name',
            field: 'name',
            editable: true,
            width: 200,
          },
          {
            headerName: 'Nick Name',
            field: 'nick_name',
            editable: true,
            width: 200,
          },
           {
            headerName: 'Position',
            field: 'position',
            editable: true,
            width: 200,
          },
          {
            headerName: 'Vendor',
            field: 'vendor_id',
            valueFormatter: this.vendorIdToName,
            editable: true,
            cellEditor: 'agPopupSelectCellEditor',
            cellEditorParams: {values: this.vendors.map(v => v.id)},
            width: 280,
          },
          {
            headerName: 'Is Active',
            field: 'is_active',
            cellRenderer: this.isActiveCheckboxRenderer,
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: [
                'empty',
                { displayKey: 'active', displayName: 'Active', test: function(filterValue, cellValue) { return cellValue == 'true' }, hideFilterInput: true },
                { displayKey: 'inactive', displayName: 'Inactive', test: function(filterValue, cellValue) { return cellValue == 'false' }, hideFilterInput: true },
              ],
            },
            width: 100,
            cellStyle: { textAlign: "center" },
          },
        ]
      },

      resetWarningMessage () {
        return "Are you sure you would like to reset passwords for " + this.selectedRowCount + " User(s). Password reset mail will be sent to those User(s)."
      },

      deleteWarningMessage () {
        return "Are you sure you would like to delete " + this.selectedRowCount + " User(s)."
      }
    },

    created() {
      window.addEventListener("resize", this.gridAutofit)
    },

    destroyed () {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      loadData () {
        this.gridApi.showLoadingOverlay()
        this.$http.get('/users').then(response => {
          this.users = response.data
        })

        this.$http.get('/vendors').then(response => {
          this.vendors = response.data
        })
      },

      onCellValueChanged (user) {
        user.data.edited = true
      },

      getEditedUsers () {
        return this.users.filter(user => user.edited)
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      isActiveCheckboxRenderer (params) {
        let input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.addEventListener('click', function (event) {
            params.value = !params.value;
            params.node.data.is_active = params.value;
            params.node.data.edited = true
        });
        return input;
      },

      save () {
        let loadingNoty = this.$noty.loading()
        let params = {
          users: this.getEditedUsers()
        }
        this.$http.post('/users/update_records', params).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data.message)
          this.loadData()
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
          this.loadData()
        })
      },

      openCreationModal () {
        this.$refs.userCreationModal.open()
      },

      deleteConfirmation () {
        let selectedItems = this.gridApi.getSelectedRows();
        if (selectedItems.length > 0){
          this.selectedRowCount = selectedItems.length
          this.$refs.deleteConfirmationModal.open()
        }
        else {
          this.$noty.error('Please select records to delete')
        }
      },

      deleteRows () {
        let loadingNoty = this.$noty.loading()
        let selectedRowIds = this.gridApi.getSelectedRows().map(row => row.id);
        let params = { users: selectedRowIds }
        this.$refs.deleteConfirmationModal.close()
        this.$http.delete('/users/delete_records', {params: params}).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data)
          this.loadData()
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
          this.loadData()
        })
      },

      viewLog() {
        let selectedItems = this.gridApi.getSelectedRows();
        if (selectedItems.length === 1){
          this.$refs.logModal.open(selectedItems[0].id, 'User')
        }
        else {
          this.$noty.error('Please select one record to view log')
        }
      },

      vendorIdToName (id) {
        let vendor = this.vendors.find( v => v.id === id.value )
        return vendor ? vendor.name : ''
      },

      gridAutofit () {
        if (!document.querySelector('#userGrid')) return

        document.querySelector('#userGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#adminPanelTabs').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      },

      resetConfirmation () {
        let selectedItems = this.gridApi.getSelectedRows();
        if (selectedItems.length > 0){
          this.selectedRowCount = selectedItems.length
          this.$refs.resetConfirmationModal.open()
        }
        else {
          this.$noty.error('Please select records to reset')
        }
      },

      resetPassword () {
        let loadingNoty = this.$noty.loading()
        let selectedRowIds = this.gridApi.getSelectedRows().map(row => row.id)
        let params = { users: selectedRowIds }
        this.$refs.resetConfirmationModal.close()
        this.$http.post('/users/reset_passwords', params).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data)
          this.loadData()
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
          this.loadData()
        })
      }
    }
  }
</script>
