<template>
  <div>
    <div class="overflow-hidden">
      <div id="actionBar">
        <div class="flex items-center justify-between p-3">
          <h1 class="heading">
            Pending Quotations
          </h1>
          <div>
            <button
              class="btn btn-primary-invert px-2"
              @click="loadData()"
            >
              Refresh
            </button>
            <button
              class="btn btn-primary-invert px-2"
              @click="openHistory()"
            >
              View History
            </button>
          </div>
        </div>
      </div>
      <ag-grid-vue
        id="quotationGrid"
        class="ag-theme-alpine"
        row-selection="single"
        row-drag-managed="true"
        animate-rows="true"
        :column-defs="columnDefs"
        :row-data="quotations"
        :get-row-node-id="getRowNodeId"
        :tooltip-show-delay="0"
        :default-col-def="{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          floatingFilterComponentParams: {suppressFilterButton:true},
          width: 100,
        }"
        :modules="modules"
        @grid-ready="onGridReady"
        @sort-changed="onSortChanged"
        @filter-changed="onFilterChanged"
      />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { formatDate, DateComparator } from '../lib/date_helper.js';
  import Button from '../components/cellRendererFramework/Button.vue'


  export default {
    components: {
      AgGridVue,
      Button
    },

    data () {
      return {
        quotations: [],
        gridApi: null,
        getRowNodeId: null,
        modules: [ClientSideRowModelModule]
      }
    },

    computed: {
      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          {
            headerName: 'Ref#',
            field: 'external_id',
            width: 80,
          },
          {
            headerName: 'Business Unit',
            field: 'business_unit_name',
            tooltipField: 'business_unit_name',
            width: 200,
          },
          {
            headerName: 'Buyer Name',
            field: 'buyer_name',
            tooltipField: 'buyer_name',
            width: 200,
          },
          {
            headerName: 'Submit Before',
            field: 'submit_before_date',
            valueFormatter: (params) => { return formatDate(params.data.submit_before_date) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: (params) => { return new Date(params.value + ' 00:00') - new Date() < 86400000 ? ['text-center', 'text-red-600', 'font-bold'] : 'text-center' },
            sort: 'asc'
          },
          {
            headerName: 'Date From',
            field: 'date_from',
            valueFormatter: (params) => { return formatDate(params.data.date_from) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            headerName: 'Date To',
            field: 'date_to',
            valueFormatter: (params) => { return formatDate(params.data.date_to) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            headerName: 'Comments',
            field: 'comments',
            tooltipField: 'comments',
            width: 200,
          },
          {
            headerName: 'Created Date',
            field: 'created_date',
            valueFormatter: (params) => { return formatDate(params.data.created_date) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            cellRendererFramework: 'Button',
            cellRendererParams: { clickFunction: this.viewQuotation, buttonName: 'View', class: 'btn-small btn-primary-invert view-quotation' },
            pinned: 'right',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 100,
            cellStyle: { textAlign: 'center', background: '#FCFCFC' },
          },
        ]
      },
    },

    created () {
      window.addEventListener("resize", this.gridAutofit)
    },

    beforeMount () {
      this.getRowNodeId = data => { return data.id } //To set own Row ID instead of ID generated by AgGrid (https://www.ag-grid.com/javascript-grid-callbacks/)
    },

    destroyed () {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      viewQuotation (id) {
        this.$router.push({ name: 'quotation_detail_path', params: { id: id }})
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      loadData () {
        this.gridApi.showLoadingOverlay()
        this.$http.get('/quotations', {params: {status: 'pending'}}).then(response => {
          this.quotations = response.data
        })
      },

      openHistory () {
        let params = {
          status: ['submitted', 'expired'],
          createdFrom: new Date(Date.now() - 12096e5).toISOString().slice(0,10), // 12096e5 is two weeks in milliseconds
          createdTo: new Date().toISOString().slice(0,10)
        }
        this.$router.push({ name: 'quotation_history_path', query: params })
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      gridAutofit () {
        if (!document.querySelector('#quotationGrid')) return

        document.querySelector('#quotationGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      }
    }
  }
</script>
