<template>
  <div>
    <quotation-history-dialog ref="quotationHistoryModal" />
    <div class="overflow-hidden">
      <div id="actionBar">
        <div class="flex items-center justify-between p-3">
          <h1 class="heading">
            Quotations History
          </h1>
          <div>
            <button
              class="btn btn-primary-invert px-2"
              @click="openHistoryDialog()"
            >
              Update Filter
            </button>
            <button
              class="btn btn-primary-invert px-2"
              @click="openPending()"
            >
              View Pending
            </button>
          </div>
        </div>
      </div>

      <ag-grid-vue
        id="quotationHistoryGrid"
        class="ag-theme-alpine"
        row-selection="single"
        row-drag-managed="true"
        animate-rows="true"
        :column-defs="columnDefs"
        :row-data="quotations"
        :tooltip-show-delay="0"
        :default-col-def="{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          floatingFilterComponentParams: {suppressFilterButton:true},
          width: 100,
        }"
        :modules="modules"
        @grid-ready="onGridReady"
        @sort-changed="onSortChanged"
        @filter-changed="onFilterChanged"
      />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import Button                         from '../components/cellRendererFramework/Button.vue'
  import Modal                          from '../components/shared/Modal.vue'
  import QuotationHistoryDialog         from '../components/QuotationHistoryDialog.vue'
  import { formatDate, DateComparator } from '../lib/date_helper.js';

  export default {
    components: {
      AgGridVue,
      Button,
      QuotationHistoryDialog,
    },

    data () {
      return {
        quotations: [],
        gridApi: null,
        modules: [ClientSideRowModelModule]
      }
    },

    computed: {
      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          {
            headerName: 'Ref#',
            field: 'external_id',
            width: 80,
          },
          {
            headerName: 'Business Unit',
            field: 'business_unit_name',
            tooltipField: 'business_unit_name',
            width: 200,
          },
          {
            headerName: 'Buyer Name',
            field: 'buyer_name',
            tooltipField: 'buyer_name',
            width: 200,
          },
          {
            headerName: 'Submit Before',
            field: 'submit_before_date',
            valueFormatter: (params) => { return formatDate(params.data.submit_before_date) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            headerName: 'Date From',
            field: 'date_from',
            valueFormatter: (params) => { return formatDate(params.data.date_from) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            headerName: 'Date To',
            field: 'date_to',
            valueFormatter: (params) => { return formatDate(params.data.date_to) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
          },
          {
            headerName: 'Comments',
            field: 'comments',
            tooltipField: 'comments',
            width: 200,
          },
          {
            headerName: 'Created Date',
            field: 'created_date',
            valueFormatter: (params) => { return formatDate(params.data.created_date) },
            filter: 'agDateColumnFilter',
            filterParams: { comparator: function (filterLocalDateAtMidnight, cellValue) { return DateComparator(filterLocalDateAtMidnight, cellValue) } },
            width: 150,
            cellClass: 'text-center',
            sort: 'desc'
          },
          {
            cellRendererFramework: 'Button',
            cellRendererParams: { clickFunction: this.viewQuotation, buttonName: 'View', class: 'btn-small btn-primary-invert' },
            pinned: 'right',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 130,
            cellStyle: { textAlign: 'center', background: '#FCFCFC' },
          },
        ]
      },
    },

    created() {
      window.addEventListener("resize", this.gridAutofit)
    },

    destroyed() {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      viewQuotation (id) {
        this.$router.push({ name: 'quotation_detail_path', params: { id: id }})
      },

      loadData () {
        this.gridApi.showLoadingOverlay()
        let statusParams = this.$route.query.status.length > 0 ? this.$route.query.status : 'null'
        let params = {
          status: statusParams,
          created_from_date: this.$route.query.createdFrom,
          created_to_date: this.$route.query.createdTo,
        }
        this.$http.get('/quotations', {params: params}).then(response => {
          this.quotations = response.data
        })
      },

      openHistoryDialog () {
        this.$refs.quotationHistoryModal.open();
      },

      openPending () {
        this.$router.push({ name: 'pending_quotation_path' })
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      gridAutofit () {
        if (!document.querySelector('#quotationHistoryGrid')) return
        document.querySelector('#quotationHistoryGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      }
    }
  }
</script>
