<template>
  <modal ref="modal">
    <template #header>
      Create New User
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <form
        class="w-full max-w-lg"
        @submit.prevent="create"
      >
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Email </label>
            <input
              id="email-input"
              v-model="email"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Name </label>
            <input
              id="name-input"
              v-model="name"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Nick Name </label>
            <input
              id="nickname-input"
              v-model="nickName"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Position </label>
            <input
              id="position-input"
              v-model="position"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Vendor </label>
            <div class="relative">
              <select
                id="vendor-id-input"
                v-model="vendorId"
                class="modal-input"
              >
                <option
                  v-for="vendor in vendors"
                  :key="vendor.id"
                  :value="vendor.id"
                >
                  {{ vendor.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Is Active? </label>
            <input
              id="is-active-input"
              v-model="isActive"
              type="checkbox"
            >
          </div>
        </div>
        <input
          v-show="false"
          type="submit"
        >
      </form>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          id="create-user-submit-btn"
          class="btn btn-primary-invert"
          @click="create()"
        >
          Create
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from '../shared/Modal.vue'
  import bus   from '../../event-bus.js'

  export default {
    components: {
      Modal
    },

    props: {
      vendors: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        email: '',
        name: '',
        vendorId: '',
        nickName: '',
        position: '',
        isActive: true
      }
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      create () {
        let loadingNoty = this.$noty.loading()
        let params = {
          email: this.email,
          name: this.name,
          vendor_id: this.vendorId,
          nick_name: this.nickName,
          position: this.position,
          is_active: this.isActive
        }
        this.$http.post('/users', {user: params}).then(response => {
          loadingNoty.close()
          this.$noty.success('User successfully created.')
          this.$parent.loadData()
          this.close()
          this.clearData()
        }, error => {
          loadingNoty.close()
          let errorMessage = error.message || error.response.data
          this.$noty.error(errorMessage)
          this.$parent.loadData()
        })
      },

      clearData () {
        this.email = ''
        this.name = ''
        this.vendorId = ''
        this.nickName = ''
        this.position = ''
        this.isActive = true
      }
    }
  }
</script>
