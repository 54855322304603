<template>
  <modal ref="modal">
    <template #header>
      Create New External App
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <form
        class="w-full max-w-lg"
        @submit.prevent="create"
      >
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Name </label>
            <input
              id="name-input"
              v-model="name"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> URL </label>
            <input
              id="url-input"
              v-model="url"
              class="modal-input"
              type="text"
            >
          </div>
        </div>
        <input
          v-show="false"
          type="submit"
        >
      </form>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          id="create-external-app-submit-btn"
          class="btn btn-primary-invert"
          @click="create()"
        >
          Create
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from '../shared/Modal.vue'
  import bus   from '../../event-bus.js'

  export default {
    components: {
      Modal
    },

    data () {
      return {
        name: '',
        url: ''
      }
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      create () {
        let loadingNoty = this.$noty.loading()
        let params = {
          name: this.name,
          url: this.url
        }

        this.$http.post('/external_apps', {external_app: params}).then(response => {
          loadingNoty.close()
          this.$noty.success('External App successfully created.')
          this.$parent.loadData()
          this.close()
          this.clearData()
        }, error => {
          let errorMessage = error.message || error.response.data
          loadingNoty.close()
          this.$noty.error(errorMessage)
          this.$parent.loadData()
        })
      },

      clearData () {
        this.name =''
        this.url =''
      },

    }
  }
</script>
