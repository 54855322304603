var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"modal-style":"height: 500px; width: 75%"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n    AttachedFiles\n  ")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"items-center justify-between flex"},[_c('button',{staticClass:"btn btn-primary-invert",on:{"click":function($event){return _vm.openFileUploadDialog()}}},[_vm._v("\n        Upload\n      ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary-invert",on:{"click":function($event){return _vm.close()}}},[_vm._v("\n        Close\n      ")])])]},proxy:true}])},[_c('confirmation-modal',{ref:"confirmDeleteModal",attrs:{"confirm-button-text":"Delete","confirm-header-text":"Confirm Delete!","message":"Are you sure you want to Delete?","confirm":_vm.deleteAttachedFile}}),_vm._v(" "),_c('input',{ref:"attachedFileUpload",staticStyle:{"display":"none"},attrs:{"id":"fileInput","type":"file"},on:{"change":_vm.uploadAttachedFile}}),_vm._v(" "),_vm._v(" "),_c('ag-grid-vue',{staticClass:"ag-theme-alpine",attrs:{"id":"attachedFilesUploadGrid","row-drag-managed":"true","animate-rows":"true","column-defs":_vm.columnDefs,"row-data":_vm.attachedFiles,"default-col-def":{
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponentParams: {suppressFilterButton:true},
      width: 100,
    },"modules":_vm.modules},on:{"grid-ready":_vm.onGridReady,"sort-changed":_vm.onSortChanged,"filter-changed":_vm.onFilterChanged}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }