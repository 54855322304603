<template>
  <div
    v-if="svgComponent != null"
    class="svg-container"
  >
    <component
      :is="svgComponent"
      class="svg"
      @click="params.clickFunction(params.data.id)"
    />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        params: {},
        svgComponent: null
      }
    },

    mounted () {
      this.svgComponent = this.params.svgComponent
    }
  }
</script>
<style scoped>
  .svg {
    height: 25px;
    margin: auto;
    cursor: pointer;
  }
</style>
