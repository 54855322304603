<template>
  <div>
    <new-password-modal ref="passwordModal" />
    <div class="overflow-hidden">
      <div
        id="header"
        class="flex items-center justify-between p-3"
      >
        <h1 class="heading">
          User Profile
        </h1>
      </div>

      <div class="w-full max-w-md mx-auto p-4">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Email </label>
            <input
              v-model="email"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Name </label>
            <input
              id="name-input"
              v-model="name"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Nick Name </label>
            <input
              v-model="nickName"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Position </label>
            <input
              v-model="position"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex items-center justify-between py-4">
          <button
            class="btn btn-primary-invert"
            @click="openChangePasswordModal()"
          >
            Change Password
          </button>
          <button
            id="save"
            class="btn btn-primary-invert"
            @click="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import NewPasswordModal  from '../components/NewPasswordModal.vue'

  export default {
    components: {
      NewPasswordModal
    },

    data () {
      return  {
        email: '',
        name: '',
        nickName: '',
        password: '',
        passwordConfirmation: '',
        position: ''
      }
    },

    mounted () {
      this.loadData()
    },

    methods: {
      loadData () {
        this.$http.get('/users/current').then(response => {
          this.setUserInfoFromResponse(response)
        })
      },

      save () {
        let loadingNoty = this.$noty.loading()
        let params = {
          email: this.email,
          name: this.name,
          nick_name: this.nickName,
          position: this.position
        }
        this.$http.post('/users/current', {user: params}).then(response => {
          this.setUserInfoFromResponse(response)
          loadingNoty.close()
          this.$noty.success('Updated')
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
        })
      },

      setUserInfoFromResponse (response) {
        this.email = response.data.email
        this.name = response.data.name
        this.nickName = response.data.nick_name
        this.position = response.data.position
      },

      openChangePasswordModal () {
        this.$refs.passwordModal.open()
      }
    }
  }
</script>
