<template>
  <modal
    ref="modal"
    modal-style="width: 400px"
  >
    <template #header>
      {{ confirmHeaderText }}
    </template>

    <div class="text-center p-4">
      {{ message }}
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert close-btn"
          @click="close()"
        >
          Close
        </button>
        <button
          class="btn btn-primary-invert confirm-btn"
          @click="confirmAndClose()"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from './Modal.vue'

  export default {
    components: {
      Modal
    },

    props: {
      confirmButtonText: {
        type: String,
        default: 'Confirm',
      },
      confirmHeaderText: {
        type: String,
        default: 'Confirmation',
      },
      confirm: {
        type: Function,
        default: () => {},
      },
      message: {
        type: String,
        default: '',
      },
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },
      confirmAndClose () {
        this.confirm()
        this.close()
      }
    }
  }
</script>
