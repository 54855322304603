var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('quotation-history-dialog',{ref:"quotationHistoryModal"}),_vm._v(" "),_c('div',{staticClass:"overflow-hidden"},[_c('div',{attrs:{"id":"actionBar"}},[_c('div',{staticClass:"flex items-center justify-between p-3"},[_c('h1',{staticClass:"heading"},[_vm._v("\n          Quotations History\n        ")]),_vm._v(" "),_c('div',[_c('button',{staticClass:"btn btn-primary-invert px-2",on:{"click":function($event){return _vm.openHistoryDialog()}}},[_vm._v("\n            Update Filter\n          ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary-invert px-2",on:{"click":function($event){return _vm.openPending()}}},[_vm._v("\n            View Pending\n          ")])])])]),_vm._v(" "),_c('ag-grid-vue',{staticClass:"ag-theme-alpine",attrs:{"id":"quotationHistoryGrid","row-selection":"single","row-drag-managed":"true","animate-rows":"true","column-defs":_vm.columnDefs,"row-data":_vm.quotations,"tooltip-show-delay":0,"default-col-def":{
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {suppressFilterButton:true},
        width: 100,
      },"modules":_vm.modules},on:{"grid-ready":_vm.onGridReady,"sort-changed":_vm.onSortChanged,"filter-changed":_vm.onFilterChanged}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }