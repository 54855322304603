<template>
  <modal ref="modal">
    <template #header>
      Create New Business Unit
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <form
        class="w-full max-w-lg"
        @submit.prevent="create"
      >
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Name </label>
            <input
              id="name-input"
              v-model="name"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Timezone </label>
            <input
              id="timezone-input"
              v-model="timezone"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Comment </label>
            <textarea
              id="comment-input"
              v-model="comment"
              class="modal-input"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> External App </label>
            <div class="relative">
              <select
                id="external-app-id-input"
                v-model="externalAppId"
                class="modal-input"
              >
                <option
                  v-for="externalApp in externalApps"
                  :key="externalApp.id"
                  :value="externalApp.id"
                >
                  {{ externalApp.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Is Active? </label>
            <input
              v-model="isActive"
              type="checkbox"
            >
          </div>
        </div>
        <input
          v-show="false"
          type="submit"
        >
      </form>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          id="create-business-unit-submit-btn"
          class="btn btn-primary-invert"
          @click="create()"
        >
          Create
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import bus   from '../../event-bus.js'
  import Modal from '../shared/Modal.vue'

  export default {
    components: {
      Modal
    },

    props: {
      externalApps: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        name: '',
        timezone: '',
        comment: '',
        externalAppId: '',
        isActive: true
      }
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      create () {
        let loadingNoty = this.$noty.loading()
        let params = {
          name: this.name,
          timezone: this.timezone,
          comment: this.comment,
          external_app_id: this.externalAppId,
          is_active: this.isActive
        }

        this.$http.post('/business_units', {business_unit: params}).then(response => {
          loadingNoty.close()
          this.$noty.success('Business Unit successfully created.')
          this.$parent.loadData()
          this.close()
          this.clearData()
        }, error => {
          let errorMessage = error.message || error.response.data
          loadingNoty.close()
          this.$noty.error(errorMessage)
          this.$parent.loadData()
        })
      },

      clearData () {
        this.name = ''
        this.comment = ''
        this.timezone = ''
        this.externalAppId = ''
        this.isActive = true
      },

    }
  }
</script>
