<template>
  <nav
    id="navbar"
    class="navbar"
  >
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <router-link
        id="logo-button"
        href="#"
        title="Home"
        to="/"
      >
        <img src="bee.png">
      </router-link>
    </div>

    <div class="flex items-center justify-end">
      <router-link
        id="home-button"
        href="#"
        title="Home"
        class="px-2"
        to="/"
      >
        <home-svg />
      </router-link>

      <router-link
        id="purchase-order-list-button"
        href="#"
        title="PO"
        class="px-2"
        to="/purchase_orders_list"
      >
        <purchase-order-svg />
      </router-link>

      <router-link
        v-if="canViewAdminPanel"
        id="admin-panel-button"
        href="#"
        title="Admin"
        class="px-2"
        to="/admin_panel"
      >
        <config-svg />
      </router-link>

      <router-link
        id="user-profile-button"
        href="#"
        title="Profile"
        class="px-2"
        to="/user_profile"
      >
        <edit-user-svg />
      </router-link>

      <a
        id="logout-btn"
        class="px-2"
        href="#"
        title="Logout"
        @click="logout()"
      >
        <logout-svg />
      </a>
    </div>
  </nav>
</template>
<script>
import HomeSvg from '../../../assets/home.svg'
import PurchaseOrderSvg from '../../../assets/po.svg'
import ConfigSvg from '../../../assets/config.svg'
import EditUserSvg from '../../../assets/edit-user.svg'
import LogoutSvg from '../../../assets/logout.svg'
export default {
  components: {
    HomeSvg,
    PurchaseOrderSvg,
    ConfigSvg,
    EditUserSvg,
    LogoutSvg
  },
  computed: {
    canViewAdminPanel () {
      return ['root', 'admin'].includes(this.$userRole)
    }
  },
  methods: {
    logout () {
      this.$http.delete('/users/sign_out').then(() => {
        location.reload()
      })
    }
  }
}
</script>
