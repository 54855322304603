import axios from 'axios';
import qs from 'qs';
import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router';
import VueNoty from './lib/vue_noty.js'

Vue.use(VueRouter);
Vue.use(VueNoty)

// this gets rails' token and use it to prevent cross site forgery
axios.defaults.headers.common = {
  'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
};
// this changes from using [] for arrays to repeating the params.
// needed for tomcat.
axios.defaults.paramsSerializer = (params) => {
	return qs.stringify(params, {arrayFormat: 'brackets'});
}

// automatically redirects to root if user is not logged in
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    window.location.href = '/'
  }
  return Promise.reject(error)
})

// assign axios to Vue.$http so its available without importing
Vue.prototype.$http = axios;

Vue.config.productionTip = false

import PendingQuotation from './pages/PendingQuotation.vue';
import QuotationDetail from './pages/QuotationDetail.vue';
import QuotationHistory from './pages/QuotationHistory.vue';
import PurchaseOrder from './pages/PurchaseOrder.vue';
import UserProfile from './pages/UserProfile.vue';
import AdminPanel from './pages/AdminPanel.vue';
import NotFound from './pages/NotFound.vue';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: PendingQuotation, name: 'pending_quotation_path' },
    { path: '/quotation/:id', component: QuotationDetail, name: 'quotation_detail_path' },
    { path: '/quotation_history', component: QuotationHistory, name: 'quotation_history_path' },
    { path: '/purchase_orders_list', component: PurchaseOrder, name: 'purchase_orders_path' },
    { path: '/user_profile', component: UserProfile, name: 'user_profile_path' },
    { path: '/admin_panel', component: AdminPanel, name: 'admin_panel_path' },
    { path: '/404', component: NotFound, name: '404' },
    { path: '*', redirect: { name: '404' } }
  ]
});

export default router;
