<template>
  <div>
    <div>
      <ul
        id="adminPanelTabs"
        class="list-reset flex border-b"
      >
        <li
          v-if="adminAccess"
          class="-mb-px mr-1 cursor-pointer"
        >
          <a
            class="inline-block py-2 px-4 text-primary text-md font-bold"
            :class="tabClass('users')"
            @click="selectTab('users')"
          >Users</a>
        </li>
        <li
          v-if="adminAccess"
          class="-mb-px mr-1 cursor-pointer"
        >
          <a
            class="inline-block py-2 px-4 text-primary text-md font-bold"
            :class="tabClass('vendors')"
            @click="selectTab('vendors')"
          >Vendors</a>
        </li>
        <li
          v-if="rootAccess"
          class="-mb-px mr-1 cursor-pointer"
        >
          <a
            class="inline-block py-2 px-4 text-primary text-md font-bold"
            :class="tabClass('exApps')"
            @click="selectTab('exApps')"
          >External Apps</a>
        </li>
        <li
          v-if="rootAccess"
          class="-mb-px mr-1 cursor-pointer"
        >
          <a
            class="inline-block py-2 px-4 text-primary text-md font-bold"
            :class="tabClass('businessUnit')"
            @click="selectTab('businessUnit')"
          >Business Units</a>
        </li>
      </ul>

      <business-unit-list v-if="selected==='businessUnit'&&rootAccess" />
      <external-app-list v-if="selected==='exApps'&&rootAccess" />
      <user-list v-if="selected==='users'&&adminAccess" />
      <vendor-list v-if="selected==='vendors'&&adminAccess" />
    </div>
  </div>
</template>
<script>
  import BusinessUnitList  from '../components/admin/BusinessUnitList.vue'
  import ExternalAppList   from '../components/admin/ExternalAppList.vue'
  import UserList          from '../components/admin/UserList.vue'
  import VendorList        from '../components/admin/VendorList.vue'

  export default {
    components: {
      BusinessUnitList,
      ExternalAppList,
      UserList,
      VendorList
    },

    data () {
      return {
        selected: 'users'
      }
    },

    computed: {
      rootAccess () {
        return this.$userRole === 'root'
      },
      adminAccess () {
        return ['root','admin'].includes(this.$userRole)
      }
    },

    methods: {
      selectTab (tab) {
        this.selected = tab
      },

      tabClass (tab) {
        if(this.selected === tab) {
          return 'bg-primary active-tab'
        }
      }
    }
  }
</script>
<style scoped>
  .active-tab {
    color: white !important;
    border: 1px solid silver !important;
    border-top-right-radius: 2.5rem;
  }
</style>
