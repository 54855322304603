<template>
  <div>
    <log-modal ref="logModal" />
    <confirmation-modal
      ref="deleteConfirmationModal"
      confirm-button-text="Delete"
      :confirm="deleteRows"
      :message="deleteWarningMessage"
    />
    <external-app-creation-modal ref="externalAppCreationModal" />
    <div
      id="actionBar"
      class="flex justify-end"
    >
      <div class="flex items-center justify-between p-3">
        <div>
          <button
            class="btn btn-primary-invert"
            @click="save()"
          >
            Save
          </button>
          <button
            class="btn btn-primary-invert"
            @click="openCreationModal()"
          >
            Create
          </button>
          <button
            class="btn btn-primary-invert"
            @click="deleteConfirmation()"
          >
            Delete
          </button>
          <button
            class="btn btn-primary-invert"
            @click="viewLog()"
          >
            View Log
          </button>
          <button
            class="btn btn-primary-invert px-2"
            @click="loadData()"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>

    <ag-grid-vue
      id="externalAppGrid"
      class="ag-theme-alpine"
      row-drag-managed="true"
      animate-rows="true"
      row-selection="multiple"
      enter-moves-down="true"
      enter-moves-down-after-edit="true"
      stop-editing-when-grid-loses-focus="true"
      :column-defs="columnDefs"
      :row-data="externalApps"
      :default-col-def="{
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {suppressFilterButton:true},
        width: 100,
      }"
      :modules="modules"
      @grid-ready="onGridReady"
      @cell-value-changed="onCellValueChanged"
      @sort-changed="onSortChanged"
      @filter-changed="onFilterChanged"
    />
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import bus                      from '../../event-bus.js'
  import ConfirmationModal from '../../components/shared/ConfirmationModal.vue'
  import ExternalAppCreationModal from './ExternalAppCreationModal.vue'
  import LogModal                 from '../shared/LogModal.vue'

  export default {
    components: {
      AgGridVue,
      ConfirmationModal,
      ExternalAppCreationModal,
      LogModal
    },

    data () {
      return {
        gridApi: null,
        externalApps: [],
        selectedRowCount: 0,
        modules: [ClientSideRowModelModule]
      }
    },

    computed: {
      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            pinned: 'left',
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 50,
          },
          {
            headerName: 'Name',
            field: 'name',
            editable: true,
            width: 300,
          },
          {
            headerName: 'Api Key',
            field: 'api_key',
            cellEditor: 'agLargeTextCellEditor',
            width: 300,
          },
          {
            headerName: 'URL',
            field: 'url',
            editable: true,
            width: 300,
          },
        ]
      },

      deleteWarningMessage () {
        return "Are you sure you would like to delete " + this.selectedRowCount + " External App(s)."
      }
    },

    created() {
      window.addEventListener("resize", this.gridAutofit)
    },

    destroyed () {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      loadData () {
        this.gridApi.showLoadingOverlay()
        this.$http.get('/external_apps').then(response => {
          this.externalApps = response.data
        })
      },

      onCellValueChanged (externalApp) {
        externalApp.data.edited = true
      },

      getEditedExternalApps () {
        return this.externalApps.filter(externalApp => externalApp.edited)
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      save () {
        let loadingNoty = this.$noty.loading()
        let params = {
          external_apps: this.getEditedExternalApps()
        }
        this.$http.post('/external_apps/update_records', params).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data.message)
          this.loadData()
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
          this.loadData()
        })
      },

      openCreationModal () {
        this.$refs.externalAppCreationModal.open()
      },

      deleteConfirmation () {
        let selectedItems = this.gridApi.getSelectedRows();
        if (selectedItems.length > 0){
          this.selectedRowCount = selectedItems.length
          this.$refs.deleteConfirmationModal.open()
        }
        else {
          this.$noty.error('Please select records to delete')
        }
      },

      deleteRows () {
        let loadingNoty = this.$noty.loading()
        let selectedRowIds = this.gridApi.getSelectedRows().map(row => row.id);
        let params = { external_apps: selectedRowIds }
        this.$refs.deleteConfirmationModal.close()
        this.$http.delete('/external_apps/delete_records', {params: params}).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data)
          this.loadData()
        }, error => {
          let message = error.response.data || error.message
          loadingNoty.close()
          this.$noty.error(message)
          this.loadData()
        })
      },

      viewLog() {
        let selectedItems = this.gridApi.getSelectedRows();
        if (selectedItems.length === 1){
          this.$refs.logModal.open(selectedItems[0].id, 'ExternalApp')
        }
        else {
          this.$noty.error('Please select one record to view log')
        }
      },

      gridAutofit () {
        if (!document.querySelector('#externalAppGrid')) return

        document.querySelector('#externalAppGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#adminPanelTabs').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      },

    }
  }
</script>
