<template>
  <modal ref="modal">
    <template #header>
      Create New Vendor
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <form
        class="w-full max-w-lg"
        @submit.prevent="create"
      >
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Name </label>
            <input
              id="name-input"
              v-model="name"
              class="modal-input"
              type="text"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Address </label>
            <textarea
              id="address-input"
              v-model="address"
              class="modal-input"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Comment </label>
            <textarea
              id="comment-input"
              v-model="comment"
              class="modal-input"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Is Active? </label>
            <input
              id="is-active-input"
              v-model="isActive"
              type="checkbox"
            >
          </div>
        </div>
        <input
          v-show="false"
          type="submit"
        >
      </form>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          id="create-vendor-submit-btn"
          class="btn btn-primary-invert"
          @click="create()"
        >
          Create
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from '../shared/Modal.vue'
  import bus   from '../../event-bus.js'

  export default {
    components: {
      Modal
    },

    data () {
      return {
        name: '',
        address: '',
        comment: '',
        isActive: true
      }
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      create () {
        let loadingNoty = this.$noty.loading()
        let params = {
          name: this.name,
          address: this.address,
          comment: this.comment,
          is_active: this.isActive
        }

        this.$http.post('/vendors', {vendor: params}).then(response => {
          loadingNoty.close()
          this.$noty.success('Vendor successfully created.')
          this.$parent.loadData()
          this.close()
          this.clearData()
        }, error => {
          let errorMessage = error.message || error.response.data
          loadingNoty.close()
          this.$noty.error(errorMessage)
          this.$parent.loadData()
        })
      },

      clearData () {
        this.name = ''
        this.address = ''
        this.comment = ''
        this.isActive = true
      },

    }
  }
</script>
