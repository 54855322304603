var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"modal-style":"height: 700px; width: 75%"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n    View Log\n  ")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex items-center justify-end"},[_c('button',{staticClass:"btn btn-primary-invert",on:{"click":function($event){return _vm.close()}}},[_vm._v("\n        Close\n      ")])])]},proxy:true}])},[_vm._v(" "),_c('ag-grid-vue',{staticClass:"ag-theme-alpine",attrs:{"id":"masterDataLogGrid","row-drag-managed":"true","animate-rows":"true","row-selection":"single","column-defs":_vm.columnDefs,"row-data":_vm.masterDataLogs,"default-col-def":{
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponentParams: {suppressFilterButton:true},
      width: 100,
    },"modules":_vm.modules},on:{"grid-ready":_vm.onGridReady,"sort-changed":_vm.onSortChanged,"filter-changed":_vm.onFilterChanged}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }