<template>
  <modal ref="modal">
    <template #header>
      Quotation History
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="modal-label"> Created From </label>
          <input
            id="date-from-input"
            v-model="createdFrom"
            class="modal-input"
            type="date"
          >
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="modal-label"> Created To </label>
          <input
            id="date-to-input"
            v-model="createdTo"
            class="modal-input"
            type="date"
          >
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="md:w-2/3 block text-gray-600 font-bold">
            <input
              id="submitted"
              v-model="status"
              class="mr-2 leading-tight"
              type="checkbox"
              value="submitted"
            >
            <span class="text-sm"> Submitted </span>
          </label>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="md:w-2/3 block text-gray-600 font-bold">
            <input
              id="expired"
              v-model="status"
              class="mr-2 leading-tight"
              type="checkbox"
              value="expired"
            >
            <span class="text-sm"> Expired </span>
          </label>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          class="btn btn-primary-invert"
          @click="search()"
        >
          Search
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from './shared/Modal.vue'

export default {
    components: {
      Modal
    },

    data () {
      return {
        createdFrom: new Date(Date.now() - 12096e5).toISOString().slice(0,10), // 12096e5 is two weeks in milliseconds
        createdTo: new Date().toISOString().slice(0,10),
        status: [] // can be 'expired' or 'submitted'
      }
    },

    mounted () {
      this.status = this.$route.query.status ? [this.$route.query.status].flat() : []
      if (this.$route.query.createdFrom) this.createdFrom = this.$route.query.createdFrom
      if (this.$route.query.createdTo) this.createdTo = this.$route.query.createdTo
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      search () {
        this.$router.push({ name: 'quotation_history_path', query: { status: this.status, createdFrom: this.createdFrom, createdTo: this.createdTo }}).catch(err => {})
        this.$parent.loadData()
        this.close()
      },

    }
  }
</script>
