import Noty from 'noty'

const defaults = {
  theme: 'sunset',
  timeout: 2000,
  progressBar: true,
  closeWith: ['click']
}

const VueNoty = {
  create (params) {
    return new Noty({...defaults, ...params})
  },

  show (options) {
    const noty = this.create(options)
    noty.show()
    return noty
  },

  alert (text, options = {}) {
    return this.show({...{text, type: 'alert'}, ...options})
  },

  success (text, options = {}) {
    return this.show({...{text, type: 'success'}, ...options})
  },

  error (text, options = {}) {
    return this.show({...{text, type: 'error'}, ...options})
  },

  warning (text, options = {}) {
    return this.show({...{text, type: 'warning'}, ...options})
  },

  info (text, options = {}) {
    return this.show({...{text, type: 'info'}, ...options})
  },

  loading () {
    let options = {
      text: 'Processing... Please Wait!',
      type: 'alert',
      modal: true,
      layout: 'center',
      timeout: false,
      closeWith: false,
      progressBar: true
    }
    return this.show(options)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$noty = VueNoty
  }
}
