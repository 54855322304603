<template>
  <div>
    <log-modal ref="logModal" />
    <attached-file-modal
      ref="quotationFileModal"
      resource-name="quotations"
      attached-file-name="attached_file"
    />
    <confirmation-modal
      ref="confirmationModal"
      confirm-button-text="Submit"
      :confirm="submit"
      :message="submitWarningMessage"
    />
    <div class="overflow-hidden">
      <div id="actionBar">
        <div class="flex items-center justify-between p-3">
          <h1 class="heading">
            {{ quotation.business_unit_name }} ( Ref# {{ quotation.external_id }})
          </h1>
          <div>
            <button
              class="btn btn-primary-invert"
              @click="back()"
            >
              Back
            </button>
            <button
              class="btn btn-primary-invert"
              @click="openUploadFileDialog()"
            >
              Upload File
            </button>
            <button
              class="btn btn-primary-invert"
              @click="viewLog()"
            >
              View Log
            </button>
            <button
              v-if="isPending"
              class="btn btn-primary-invert"
              @click="save(false)"
            >
              Save
            </button>
            <button
              v-if="isPending"
              class="btn btn-primary-invert"
              @click="submitConfirmation()"
            >
              Submit
            </button>
          </div>
        </div>
        <div class="flex flex-wrap justify-between text-xs px-6 pb-2">
          <div>
            Requested by {{ quotation.buyer_name }} on {{ quotation.created_date }}
            for the period of ({{ quotation.date_from }} - {{ quotation.date_to }}).
          </div>
          <div
            v-if="isPending"
            class="text-red-600 font-bold"
          >
            Please submit before {{ quotation.submit_before_date }}
          </div>
        </div>
      </div>

      <ag-grid-vue
        id="quotationDetailGrid"
        class="ag-theme-alpine"
        row-drag-managed="true"
        animate-rows="true"
        row-selection="single"
        single-click-edit="true"
        enter-moves-down="true"
        enter-moves-down-after-edit="true"
        stop-editing-when-grid-loses-focus="true"
        :column-defs="columnDefs"
        :row-data="quotation.quotation_items"
        :tooltip-show-delay="0"
        :default-col-def="{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          floatingFilterComponentParams: {suppressFilterButton:true},
          width: 100,
        }"
        :modules="modules"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged"
        @sort-changed="onSortChanged"
        @filter-changed="onFilterChanged"
      />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { formatDate }    from '../lib/date_helper.js';
  import ConfirmationModal from '../components/shared/ConfirmationModal.vue'
  import LogModal          from '../components/shared/LogModal.vue'
  import AttachedFileModal from '../components/shared/AttachedFileModal.vue'

  export default {
    components: {
      AgGridVue,
      ConfirmationModal,
      LogModal,
      AttachedFileModal
    },

    data () {
      return {
        gridApi: null,
        quotation: {
          submit_before_date: 'loading',
          business_unit_name: 'loading',
          date_to: 'loading',
          date_from: 'loading',
          status: 'loading',
          quotation_items: []
        },
        submitWarningMessage: "Are you sure you would like to save and submit? You cannot change after your submission.",
        modules: [ClientSideRowModelModule]
      }
    },

    computed: {
      isPending () {
        return this.quotation.status === 'pending'
      },

      columnDefs () {
        return [
          {
            filter: false,
            headerName: "#",
            pinned: 'left',
            sortable: false,
            suppressMovable: true,
            valueGetter: "node.rowIndex + 1",
            width: 60,
          },
          {
            headerName: 'Item Name',
            field: 'item_name',
            pinned: 'left',
            suppressMovable: true,
            width: 500,
            tooltipField: 'item_name',
          },
          {
            headerName: 'Order Unit',
            field: 'order_unit',
            width: 150,
          },
          {
            headerName: 'Currency',
            field: 'currency_name',
            width: 100,
            cellStyle: { textAlign: "center" },
          },
          {
            headerName: 'Price',
            field: 'quoted_price',
            editable: this.isPending,
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 150,
            cellClass: 'quotation-price'
          },
          {
            headerName: 'Quantity From',
            field: 'quantity_from',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 150,
          },
          {
            headerName: 'Quantity To',
            field: 'quantity_to',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 150,
          },
          {
            headerName: 'Tax Name',
            field: 'tax_name',
            width: 100,
            cellStyle: { textAlign: "center" },
          },
          {
            headerName: 'Tax Inclusive',
            field: 'is_tax_inclusive',
            cellRenderer: this.isActiveCheckboxRenderer,
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: [
                'empty',
                { displayKey: 'include', displayName: 'Include', test: function(filterValue, cellValue) { return cellValue == 'true' }, hideFilterInput: true },
                { displayKey: 'exclude', displayName: 'Exclude', test: function(filterValue, cellValue) { return cellValue == 'false' }, hideFilterInput: true },
              ],
            },
            width: 100,
            cellStyle: { textAlign: "center" },
          },
          {
            headerName: 'Foc Quantity',
            field: 'foc_quantity',
            editable: this.isPending,
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 150,
          },
          {
            headerName: 'Discount Amount',
            field: 'discount_amount',
            editable: this.isPending,
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 150,
          },
          {
            headerName: 'Comment',
            field: 'comment',
            editable: this.isPending,
            cellEditor: 'agLargeTextCellEditor',
            width: 200,
          },
          {
            headerName: 'Vendor Item Code',
            field: 'vendor_item_code',
            editable: this.isPending,
            width: 200,
          },
          {
            headerName: 'Quote Ref Number',
            field: 'quote_ref_number',
            editable: this.isPending,
            width: 200,
          },
        ]
      },
    },

    created () {
      window.addEventListener("resize", this.gridAutofit)
    },

    destroyed () {
      window.removeEventListener("resize", this.gridAutofit)
    },

    methods: {
      loadData () {
        this.gridApi.showLoadingOverlay()
        this.$http.get('/quotations/' + this.$route.params.id).then(response => {
          this.quotation = response.data
          this.quotation.submit_before_date = formatDate(this.quotation.submit_before_date)
          this.quotation.created_date = formatDate(this.quotation.created_date)
        })
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      back () {
        this.$router.go(-1)
      },

      onGridReady (params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onCellValueChanged (quotationItem) {
        quotationItem.data.edited = true
      },

      getEditedQuotationItems () {
        return this.quotation.quotation_items.filter(qi => qi.edited)
      },

      isActiveCheckboxRenderer (params) {
        let input = document.createElement('input')
        let that = this
        input.type = "checkbox"
        input.checked = params.value
        input.addEventListener('click', function (event) {
          if (that.isPending) {
            params.setValue(!params.value)
          } else {
            event.preventDefault()
          }
          event.stopPropagation()
        })
        return input
      },

      save (isSubmission = false) {
        let loadingNoty = this.$noty.loading()
        let params = {
          quotation_items: this.getEditedQuotationItems(),
          submit: isSubmission,
          quotation_id: this.$route.params.id
        }
        this.$http.post('/quotation_items/update_records', params).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data.message)
          if (isSubmission) this.back()
          this.loadData()
        }, error => {
          let message = error.response.data.message || error.message
          loadingNoty.close()
          this.$noty.error(message)
          if (isSubmission) this.$refs.confirmationModal.close()
          this.loadData()
        })
      },

      viewLog() {
        let selectedItem = this.gridApi.getSelectedRows()[0];
        if (selectedItem){
          this.$refs.logModal.open(selectedItem.id, 'QuotationItem')
        }
        else {
          this.$noty.error('Please select one record to view log')
        }
      },

      submit () {
        this.save(true)
      },

      submitConfirmation () {
        this.$refs.confirmationModal.open()
      },

      gridAutofit () {
        if (!document.querySelector('#quotationDetailGrid')) return
        document.querySelector('#quotationDetailGrid').style.height = (
          document.documentElement.clientHeight
          - document.querySelector('#navbar').offsetHeight
          - document.querySelector('#actionBar').offsetHeight
          - document.querySelector('footer').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      },

      openUploadFileDialog () {
        this.$refs.quotationFileModal.open(this.$route.params.id)
      }
    }
  }
</script>
