<template>
  <modal
    ref="modal"
    modal-style="height: 500px; width: 75%"
  >
    <confirmation-modal
      ref="confirmDeleteModal"
      confirm-button-text="Delete"
      confirm-header-text="Confirm Delete!"
      message="Are you sure you want to Delete?"
      :confirm="deleteAttachedFile"
    />
    <input
      id="fileInput"
      ref="attachedFileUpload"
      type="file"
      style="display:none"
      @change="uploadAttachedFile"
    >
    <template #header>
      AttachedFiles
    </template>

    <ag-grid-vue
      id="attachedFilesUploadGrid"
      class="ag-theme-alpine"
      row-drag-managed="true"
      animate-rows="true"
      :column-defs="columnDefs"
      :row-data="attachedFiles"
      :default-col-def="{
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {suppressFilterButton:true},
        width: 100,
      }"
      :modules="modules"
      @grid-ready="onGridReady"
      @sort-changed="onSortChanged"
      @filter-changed="onFilterChanged"
    />

    <template #footer>
      <div class="items-center justify-between flex">
        <button
          class="btn btn-primary-invert"
          @click="openFileUploadDialog()"
        >
          Upload
        </button>
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { formatDateTime } from '../../lib/date_helper.js';
  import SvgRenderer from '../cellRendererFramework/SvgRenderer.vue'
  import ConfirmationModal from './ConfirmationModal.vue'
  import Modal from './Modal.vue'
  import DownloadSvg from '../../../assets/download.svg'
  import TrashSvg from '../../../assets/trash.svg'

  export default {
    components: {
      AgGridVue,
      SvgRenderer,
      Modal,
      DownloadSvg,
      TrashSvg,
      ConfirmationModal
    },

    props: {
      resourceName: {
        type: String,
        required: true
      },
      attachedFileName: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        gridApi: null,
        attachedFiles: [],
        poId: null,
        selectedDeleteAttachedFileId: 0,
        modules: [ClientSideRowModelModule],
        columnDefs: [
          {
            headerName: "Row",
            valueGetter: "node.rowIndex + 1",
            filter: false
          },
           {
            headerName: 'File name',
            field: 'filename',
            width: 350
          },
          {
            headerName: 'Created at',
            field: 'created_at',
            valueFormatter: (params) => { return formatDateTime(params.data.created_at) },
            width: 300,
          },
          {
            cellRendererFramework: 'SvgRenderer',
            cellRendererParams: { svgComponent: DownloadSvg, clickFunction: this.download },
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 80,
          },
          {
            cellRendererFramework: 'SvgRenderer',
            cellRendererParams: { svgComponent: TrashSvg, clickFunction: this.openConfirmDeleteModal },
            suppressMovable: true,
            sortable: false,
            filter: false,
            width: 80,
          },
        ]
      }
    },

    methods: {
      open (poId) {
        window.addEventListener("resize", this.gridAutofit)
        this.poId = poId
        this.$refs.modal.open()
      },
      close () {
        window.removeEventListener("resize", this.gridAutofit)
        this.$refs.modal.close()
      },

      loadData () {
        this.gridApi.showLoadingOverlay()
        this.$http.get(`/${this.resourceName}/${this.poId}/${this.attachedFileName}s`).then(response => {
          this.attachedFiles = response.data
        })
      },

      onGridReady(params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      download(id) {
        let attachedFile = this.attachedFiles.find(attachedFile => attachedFile.id === id)
        window.open(attachedFile.url, '_blank')
      },

      deleteAttachedFile() {
        let loadingNoty = this.$noty.loading()
        let params = {
          file_id: this.selectedDeleteAttachedFileId
        }
        this.$http.delete(`/${this.resourceName}/${this.poId}/delete_${this.attachedFileName}`, {params}).then(response => {
          loadingNoty.close()
          this.$noty.success(response.data.message)
          this.loadData()
        }, error => {
          let message = error.response.data.message
          loadingNoty.close()
          this.$noty.error(message)
        })
      },

      openFileUploadDialog() {
        this.$refs.attachedFileUpload.click()
      },

      uploadAttachedFile() {
        let loadingNoty = this.$noty.loading()

        if (this.$refs.attachedFileUpload.files.length != 1){
          loadingNoty.close()
          return
        }

        if (this.$refs.attachedFileUpload.files[0].size > 20971520) {
          loadingNoty.close()
          this.$noty.error('File Size is too Large! (Maximum 20MB)')
          return
        }

        let formData = new FormData()
        formData.append('file', this.$refs.attachedFileUpload.files[0])

        this.$http.post(`/${this.resourceName}/${this.poId}/upload_${this.attachedFileName}`, formData).then(response => {
          if (response.data.error) {
            loadingNoty.close()
            this.$noty.error(response.data.message)
          } else {
            this.loadData()
            loadingNoty.close()
            this.$noty.success(response.data.message)
          }
        })
      },

      gridAutofit () {
        if (!document.querySelector('#attachedFilesUploadGrid')) return
        document.querySelector('#attachedFilesUploadGrid').style.height = (
          document.querySelector('#modalDialog').offsetHeight
          - document.querySelector('#modalDialogHeader').offsetHeight
          - document.querySelector('#modalDialogFooter').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      },

      openConfirmDeleteModal (id) {
        this.selectedDeleteAttachedFileId = id
        this.$refs.confirmDeleteModal.open()
      }
    }
  }
</script>
