<template>
  <div id="app">
    <nav-bar />
    <router-view />
  </div>
</template>
<script>
import NavBar from './components/shared/NavBar.vue'
import Vue from 'vue/dist/vue.esm';
export default {
  components: {
    NavBar
  },
  props: {
    userRole: {
      type: String,
      default: 'default'
    }
  },
  created () {
    Vue.prototype.$userRole = this.userRole
  }
}
</script>
