export { formatDate, formatDateTime, DateComparator }

const formatDate = (string) => { return new Date(string).toDateString() }
const formatDateTime = (string) => { return new Date(string).toString() }

// filterParams comparator function for agDateColumnFilter
function DateComparator(filterLocalDateAtMidnight, cellValue) {
  let dateParts = cellValue.split('-')
  let cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))
  if ( cellDate.getTime() === filterLocalDateAtMidnight.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
}
