<template>
  <modal
    ref="modal"
    modal-style="height: 700px; width: 75%"
  >
    <template #header>
      View Log
    </template>

    <ag-grid-vue
      id="masterDataLogGrid"
      class="ag-theme-alpine"
      row-drag-managed="true"
      animate-rows="true"
      row-selection="single"
      :column-defs="columnDefs"
      :row-data="masterDataLogs"
      :default-col-def="{
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {suppressFilterButton:true},
        width: 100,
      }"
      :modules="modules"
      @grid-ready="onGridReady"
      @sort-changed="onSortChanged"
      @filter-changed="onFilterChanged"
    />

    <template #footer>
      <div class="flex items-center justify-end">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import { AgGridVue } from '@ag-grid-community/vue'
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { formatDateTime } from '../../lib/date_helper.js';
  import Modal from './Modal.vue'

  export default {
    components: {
      AgGridVue,
      Modal
    },

    data () {
      return {
        gridApi: null,
        masterDataLogs: [],
        modules: [ClientSideRowModelModule],
        columnDefs: [
           {
            headerName: 'Action',
            field: 'action',
            width: 350,
          },
          {
            headerName: "Row",
            valueGetter: "node.rowIndex + 1",
            filter: false
          },
          {
            headerName: 'User Name',
            field: 'user_name',
            width: 200,
          },
          {
            headerName: 'Client Info',
            field: 'client_info',
            width: 300,
          },
          {
            headerName: 'Created at',
            field: 'created_at',
            valueFormatter: (params) => { return formatDateTime(params.data.created_at) },
            width: 300,
          },
        ]
      }
    },

    methods: {
      // onOpen and onClose replaces created() and destroyed() lifecycle hooks
      // this is because modals are mounted right when the page load
      // but some methods should be executed until modal is open
      onOpen () {
        window.addEventListener("resize", this.gridAutofit)
      },

      open (klassId, klassType) {
        this.onOpen()
        this.klassId = klassId
        this.klassType = klassType
        this.$refs.modal.open()
      },

      onClose () {
        window.removeEventListener("resize", this.gridAutofit)
      },

      close () {
        this.onClose()
        this.$refs.modal.close()
      },

      loadData () {
        this.gridApi.showLoadingOverlay()
        let params = {
          klass_type: this.klassType,
          klass_id: this.klassId
        }
        this.$http.get('/master_data_logs', {params: params}).then(response => {
          this.masterDataLogs = response.data
        })
      },

      onGridReady(params) {
        this.gridApi = params.api
        this.loadData()
        this.gridAutofit()
      },

      onSortChanged(params) {
        params.api.refreshCells()
      },

      onFilterChanged(params) {
        params.api.refreshCells()
      },

      gridAutofit () {
        if (!document.querySelector('#masterDataLogGrid')) return
        document.querySelector('#masterDataLogGrid').style.height = (
          document.querySelector('#modalDialog').offsetHeight
          - document.querySelector('#modalDialogHeader').offsetHeight
          - document.querySelector('#modalDialogFooter').offsetHeight
        ) + 'px'
        this.gridApi.doLayout()
      },

    }
  }
</script>
