<template>
  <modal ref="modal">
    <template #header>
      Change Password
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <form
        class="w-full max-w-lg"
        @submit.prevent="update"
      >
        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Old Password </label>
            <input
              id="old-password-input"
              v-model="oldPassword"
              class="modal-input"
              type="password"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> New Password </label>
            <input
              id="new-password-input"
              v-model="newPassword"
              class="modal-input"
              type="password"
            >
          </div>
        </div>

        <div class="flex flex-wrap -mx-3">
          <div class="w-full px-3">
            <label class="modal-label"> Confirm Password </label>
            <input
              id="new-password-confirm-input"
              v-model="confirmPassword"
              class="modal-input"
              type="password"
            >
          </div>
        </div>
        <input
          v-show="false"
          type="submit"
        >
      </form>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          class="btn btn-primary-invert"
          @click="update()"
        >
          Update
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from './shared/Modal.vue'
  import bus   from '../event-bus.js'

  export default {
    components: {
      Modal
    },

    data () {
      return {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    },

    methods: {
      open () {
        this.oldPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      update () {
        let loadingNoty = this.$noty.loading()
        if (this.newPassword != this.confirmPassword) {
          loadingNoty.close()
          this.$noty.error('Passwords do not match.')
        } else {
          let params = {
            current_password: this.oldPassword,
            password: this.newPassword,
            password_confirmation: this.confirmPassword
          }

          this.$http.post('/users/current_password', {user: params}).then(response => {
            loadingNoty.close()
            this.$noty.success('Password updated')
            this.close()
          }, error => {
            let errorMessage = error.response ? error.response.data : error.message
            loadingNoty.close()
            this.$noty.error(errorMessage)
          })
        }
      }
    }
  }
</script>
