<template>
  <modal ref="modal">
    <template #header>
      Purchase Order
    </template>

    <div class="w-full max-w-md mx-auto p-4">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="modal-label"> Delivery Date From </label>
          <input
            id="date-from-input"
            v-model="deliveryDateFrom"
            class="modal-input"
            type="date"
          >
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="modal-label"> Delivery Date To </label>
          <input
            id="date-to-input"
            v-model="deliveryDateTo"
            class="modal-input"
            type="date"
          >
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="btn btn-primary-invert"
          @click="close()"
        >
          Close
        </button>
        <button
          class="btn btn-primary-invert"
          @click="search()"
        >
          Search
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
  import Modal from './shared/Modal.vue'

export default {
    components: {
      Modal
    },

    data () {
      return {
        deliveryDateFrom: new Date(Date.now() - 77760e5).toISOString().slice(0,10), // 77760e5 is 90 Days in milliseconds
        deliveryDateTo: new Date(Date.now() + 77760e5).toISOString().slice(0,10)
      }
    },

    methods: {
      open () {
        this.$refs.modal.open()
      },

      close () {
        this.$refs.modal.close()
      },

      search () {
        this.$router.push({ name: 'purchase_orders_path', query: { deliveryDateFrom: this.deliveryDateFrom, deliveryDateTo: this.deliveryDateTo }}).catch(err => {})
        this.$parent.loadData()
        this.close()
      }
    }
  }
</script>
