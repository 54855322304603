// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import Vue from 'vue/dist/vue.esm';
import BaseApp from '../src/BaseApp.vue'
import router from '../src/routes.js';

window.addEventListener('load', function () {
  var appElement = document.getElementById("app");
  if (appElement) {
    const app = new Vue({
      components: {
      	BaseApp
      },
      router
    }).$mount('#app')
  }
})
